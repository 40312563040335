import { Box, Grid, LinearProgress, Paper, Typography, Select, MenuItem } from '@material-ui/core'

import Alert from '@material-ui/lab/Alert'
import Layout from 'components/Layout'
import React, { useState } from 'react'
import { UserRoles } from '@dramatikkens-hus-portal/shared'
import { gql } from 'graphql-request'
import { useGqlQuery } from 'hooks/useGqlQuery'
import { useQueryCache } from 'react-query'
import { useRouter } from 'next/router'
import { withAuthenticationRequired } from '@auth0/auth0-react'

type Props = {
  children: any
}

const GET_USER = gql`
  query getUser {
    user {
      role
    }
  }
`

const GRANT_STATISTICS = gql`
  query grantStatistics($year: Int!) {
    grantStatistics(input: { year: $year }) {
      year
      in_progress
      completed
      cancelled
    }
  }
`

const APPLICATION_STATISTICS = gql`
  query grantStatistics($year: Int!) {
    applicationStatistics(input: { year: $year }) {
      year
      in_progress
      submitted
      unassigned
      approved
      denied
    }
  }
`

const FrontPage = ({ children }: Props) => {
  const queryCache = useQueryCache()
  const router = useRouter()
  let years = []

  const yearNow = new Date().getFullYear()
  const firstYearWithData = 2011
  for (let i = yearNow; i >= firstYearWithData; i--) {
    years.push(i)
  }
  const [year, setYear] = useState(yearNow)
  const { isLoading, isFetching, isError, isSuccess, isIdle, data, error, status } = useGqlQuery(
    ['user_role'],
    GET_USER
  )

  const grantStatistics = useGqlQuery(['grant-statistics', { year: year }], GRANT_STATISTICS)

  const applicationStatistics = useGqlQuery(
    ['application-statistics', { year: year }],
    APPLICATION_STATISTICS
  )

  if (isLoading || grantStatistics.isLoading || applicationStatistics.isLoading) {
    return <LinearProgress></LinearProgress>
  }

  if (data && data.user && data.user.role === UserRoles.User) {
    router.push('/minesoknader')
    return null
  } else {
    return (
      <Layout>
        <Grid container spacing={3}>
          <Grid item xs md={12}>
            <Typography variant="h6">
              Oversikt{' '}
              <Select
                // className={classes.selectEmpty}
                placeholder="Gender" //
                value={year}
                // displayEmpty
                id="statuses"
                inputProps={{
                  // name: 'statuses',
                  shrink: false,
                }}
                // labelId="statuses-label"
                type="select"
                onChange={(evt) => {
                  console.log(evt.target.value)
                  const newYear: any = evt.target.value
                  setYear(parseInt(newYear))
                }}
                margin="dense"
                variant="outlined"
                // renderValue={(value: string[]) => {
                //   if (value.length === 0) {
                //     return 'Alle'
                //   }
                //   return value.map((item) => statusText(item)).join(', ')
                // }}
              >
                {/* <MenuItem value={'*'}>Alle</MenuItem> */}
                {years.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  )
                })}
              </Select>
            </Typography>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Godkjente søknader</Typography>
                <Typography variant="h4">
                  {applicationStatistics.data.applicationStatistics.approved}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Avslåtte søknader</Typography>
                <Typography variant="h4">
                  {applicationStatistics.data.applicationStatistics.denied}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Pågående søknader</Typography>
                <Typography variant="h4">
                  {applicationStatistics.data.applicationStatistics.in_progress}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Innsendte søknader</Typography>
                <Typography variant="h4">
                  {applicationStatistics.data.applicationStatistics.submitted}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Søknader uten saksbehandler</Typography>
                <Typography variant="h4">
                  {applicationStatistics.data.applicationStatistics.unassigned}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          {/*  */}
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Kansellerte tildelinger</Typography>
                <Typography variant="h4">
                  {grantStatistics.data.grantStatistics.cancelled}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Pågående tildelinger</Typography>
                <Typography variant="h4">
                  {grantStatistics.data.grantStatistics.in_progress}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs md={3}>
            <Paper>
              <Box textAlign="center" p={4}>
                <Typography variant="body2">Fullførte tildelinger</Typography>
                <Typography variant="h4">
                  {grantStatistics.data.grantStatistics.completed}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Layout>
    )
  }
}

export default withAuthenticationRequired(FrontPage, {
  // eslint-disable-next-line react/display-name
  onRedirecting: () => <LinearProgress />,
})
